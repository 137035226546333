import React, { useEffect, useState } from 'react'
import Header from "../components/header";
import Footer from "../components/footer";
import { Link } from "gatsby"
import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/600.css"
import "@fontsource/dm-sans/700.css";
import "@fontsource/dm-sans/400.css";
import googlebadge from "../images/google-play-badge.webp";
import tastes from "../images/volen14_png_white_background_registering_on_a_dating_app_in_the_fc49cb81-1b3a-4b65-8eb6-e9f748e9a1c5 copie.webp";
import logofull from '../images/logo-full.webp';
import date from "../images/volen14_portrait_5ee66853-a7e5-486d-a8f6-ca3545173c8e copie.webp";
import algo from "../images/illu3.webp";
import friends from "../images/friends.webp";
import hookups from "../images/hooks.webp";
import rel from "../images/rel.webp";
import feel1 from "../images/feel1.webp";
import feel2 from "../images/feel2.webp";
import appstore from "../images/apple.png";
import Scroll from '../components/scroll';
import { Helmet } from "react-helmet";
import i18next from "i18next";

// markup

const IndexPage = () => {

  const [isLocalStorageAvailable, setLocalStorageAvailable] = useState(false);
  const [language, setLanguage] = useState('en');  // default language

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      setLocalStorageAvailable(true);
      const lang = localStorage.getItem('lang') || 'en';  // default to 'en' if not set
      setLanguage(lang);
    }
  }, []);

  return (
    <div className="bg-white">
      <Scroll showBelow={250} />
      <Helmet htmlAttributes={{ lang: language }}>
        <meta charSet="utf-8" />
        <meta name="description" content={i18next.t("index.header.description")} />
        <meta name="keywords" content="Rejoice, dating, app, new, big5, concept, young, ipq, meet, people, girls" />
        <meta name="author" content="Rejoice" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{i18next.t("index.header.title")}</title>
      </Helmet>
      <Header />
      <div className="mx-auto h-screen-92 flex" id="firstDesktop">
        <div className="mt-36 hidden lg:block ml-28 w-50">
          <img src={logofull} alt="logo" className=" w-1/5 filter-drop-shadow" />
          <h2 className="text-4xl mt-6 font-bold home-rejoice-text lightpink text-shadow-sm">{i18next.t("index.block1.title")}</h2>
          <p className="font-bold dmsans mt-6 leading-10 w-1/2 text-lg text-white text-shadow-sm text-justify">{i18next.t("index.block1.text")}</p>
          <div className="flex align-center">
            <a href="https://play.google.com/store/apps/details?id=com.rejoiceapp.app" className="w-1/6 mt-6"><img src={googlebadge} alt="google-play-badge" className="" width={499} height={167} /></a>
            <a href="https://apps.apple.com/us/app/rejoice-app/id1619480472" className="w-1/6 ml-3 mt-6"><img src={appstore} alt="google-play-badge" /></a>
          </div>
        </div>
        <div className="lg:hidden w-full flex flex-col items-center text-center justify-center">
          <img src={logofull} alt="logo" className=" w-4/5 md:w-2/5" />
          <h2 className="text-4xl mt-6 font-bold home-rejoice-text lightpink text-shadow-sm">{i18next.t("index.block1.title")}</h2>
          <p className="font-bold dmsans mt-6 leading-10 w-11/12 text-white">{i18next.t("index.block1.text")}</p>
          <div className="flex justify-center">
            <a href="https://play.google.com/store/apps/details?id=com.rejoiceapp.app" className="w-2/5 md:w-1/5 mt-6"><img src={googlebadge} alt="google-play-badge" className="" /></a>
            <a href="https://apps.apple.com/us/app/rejoice-app/id1619480472" className="w-2/5 md:w-1/5 ml-3 mt-6"><img src={appstore} alt="google-play-badge" /></a>
          </div>
        </div>
      </div>
      <div className="relative mt-8 justify-center mx-auto flex" id="info">
        <div className="flex flex-col">
          <h2 className="text-4xl text-center font-bold darkpink md:mb-12 mb-6">{i18next.t("index.howItWork")}</h2>
          <div className="flex flex-row flex-wrap items-center">
            <div className='md:w-1/2 w-full md:block'> 
              <img src={tastes} alt="tastes" className="md:w-1/2 hidden md:block w-full m-auto" />
            </div>
            <div className="md:w-1/2 w-full">
              <h2 className="lg:text-4xl text-2xl text-center font-bold darkpink">{i18next.t("index.block2.title")}</h2>
              <p className="md:text-center text-justify md:text-xl text-sm w-10/12 mx-auto font-bold mt-3 lg:mt-6 darkpin">{i18next.t("index.block2.text")}</p>
            </div>
            <img src={tastes} alt="tastes" className="md:w-1/2 md:mt-0 md:mb-0 mt-6 mb-6 md:hidden block w-full" />
            <div className="flex flex-row flex-wrap justify-end items-center">
              <div className="md:w-1/2 mt-3 md:mt-0 w-full">
                <h2 className="lg:text-4xl text-2xl mb-6 text-center font-bold darkpink">{i18next.t("index.block3.title")}</h2>
                <p className="md:text-center text-justify md:text-xl text-sm w-10/12 mx-auto font-bold mt-3 lg:mt-6 darkpin">{i18next.t("index.block3.text")}</p>
              </div>
              <div className='md:w-1/2 w-full mb-6'>
                <img src={algo} alt="match" className="md:w-1/2 w-full mt-0 m-auto" />
              </div>
            </div>
            <div className="md:w-1/2 md:block hidden w-full mt-6 ">
              <img src={date} alt="date" className="md:w-1/2 md:block hidden w-full mt-6 m-auto" />
            </div>
            <div className="md:w-1/2 mt-3 md:mt-0 w-full">
              <h2 className="lg:text-4xl text-2xl text-center font-bold darkpink mb-6">{i18next.t("index.block4.title")}</h2>
              <p className="md:text-center text-justify md:text-xl text-sm w-10/12 mx-auto font-bold mt-3 lg:mt-6 darkpin">{i18next.t("index.block4.text")}</p>
            </div>
            <img src={date} alt="date" className="md:w-1/2 md:hidden block w-full  " />
          </div>
        </div>
      </div>
      <div className="mt-12 mx-auto text-center container">
        <div className="w-50 mx-auto">
          <Link key="about" to="/about"><button className="p-3 rounded-2xl text-white text-xl bg-gradient-pink">{i18next.t("index.moreDetail")}</button></Link>
        </div>
      </div>

      
      
        
      <div className="mx-auto" id="find">
        <h2 className="text-4xl text-center font-bold darkpink pt-16 pb-6">{i18next.t("index.block5.title")}</h2>
        <div className="lg:flex hidden md:container mx-auto flex-row justify-center items-center pt-16">
          <div className="w-1/2">
            <div className="w-3/4 mx-auto">
              <p className="mt-8 text-2xl text-justify text-gray-500 leading-8">
                {i18next.t("index.block5.text.p1")}
              </p>
              <p className="mt-8 text-2xl text-justify text-gray-500 leading-8">
                {i18next.t("index.block5.text.p2")}
              </p>
            </div>
          </div>
          <div className="mx-auto relative lg:mt-0 text-center pb-2 w-1/2">
            <div className="relative justify-center items-center flex flex-col mx-auto z-0  w-full">
              <img src={friends} alt="friends" className="shadow-faq-box rounded-2xl w-1/2 h-full" />
              <img src={rel} alt="Relationship" className="shadow-faq-box rounded-2xl mt-3 w-1/2 h-full" />
              <img src={hookups} alt="Hookups" className="shadow-faq-box rounded-2xl mt-3 w-1/2 h-full" />
            </div>
          </div>
        </div>
        <div className="flex lg:hidden mx-auto flex-col justify-center items-center">
          <img src={rel} alt="Relationship" className="shadow-faq-box mt-6 rounded-2xl mt-3 w-3/4 md:w-1/2 h-full" />
          <p className="mt-10 text-2xl w-3/4 text-justify text-gray-500 leading-8">
            {i18next.t("index.block5.text.p1")}
          </p>
          <img src={friends} alt="friends" className="shadow-faq-box mt-10 mb-10 rounded-2xl w-3/4 md:w-1/2 h-full" />
          <p className=" text-2xl w-3/4 text-justify text-gray-500 leading-8">
            {i18next.t("index.block5.text.p2")}
          </p>
        </div>
        <div className="container mx-auto mt-24 mb-12">
          <h2 className="text-4xl text-center font-bold darkpink">{i18next.t("index.Discover")}</h2>
          <div className="flex flex-col lg:flex-row items-center justify-center mt-24">
            <img src={feel1} alt="feel1" className="mx-auto w-1/2 lg:w-1/5 lg:rotate-6" />
            <img src={feel2} alt="feel2" className="mx-auto mt-12 lg:mt-0 w-1/2 lg:w-1/5 lg:rotate-6" />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default IndexPage;
